import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleSidebar } from "redux/actions/sidebarAction";
import { CgMenuRight } from "react-icons/cg";

const Navigation = () => {
  const dispatch = useDispatch();
  const sidebar_toggle = useSelector((state) => state.sidebarState.toggle);

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);
  const currentPath = capitalizeFirstLetter(
    useLocation().pathname.substring(1)
  );

  return (
    <div className="dashboard_navigation">
      <div className="flex items-center">
        <h2>{currentPath}</h2>
      </div>
      <button className="sidebar_toggle">
        <CgMenuRight onClick={() => dispatch(toggleSidebar(!sidebar_toggle))} />
        {/* {sidebar_toggle ? (
          <CgMenuRight
            onClick={() => dispatch(toggleSidebar(!sidebar_toggle))}
          />
        ) : (
          <RiMenuFoldLine
            onClick={() => dispatch(toggleSidebar(!sidebar_toggle))}
          />
        )} */}
      </button>
    </div>
  );
};

export default Navigation;
