import { SEARCH_PRODUCT_KEYWORD } from "constants/constants";

const initialState = {
  keyword: "",
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PRODUCT_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };

    default:
      return state;
  }
};

export default sidebarReducer;
