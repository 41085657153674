export default function Button({
  name,
  onClick,
  className,
  style,
  padding,
  width,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      style={{
        padding: padding,
        width: width,
        ...style,
      }}
      className={`main_button ${className}`}
      onClick={onClick}
    >
      {name}
    </button>
  );
}
