import React from "react";
import { AdminNavigation, AdminSidebar } from "components";

const DashboardRoute = (props) => (
  <div className="admin_dashboard">
    <AdminSidebar />
    <div className="admin_dashboard_content">
      <AdminNavigation />
      <div className="admin_dashboard_content_wrapper">{props.element}</div>
    </div>
  </div>
);

export default DashboardRoute;
