import * as ROUTES from "constants/routes";
import { en, albania } from "assets";
import { IoTrendingUpOutline } from "react-icons/io5";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosInformationCircle } from "react-icons/io";

export const sidebarLinks = [
  {
    title: "",
    children: [
      {
        id: 1,
        icon: <IoTrendingUpOutline />,
        link: "Reports",
        url: ROUTES.REPORTS,
      },
      {
        id: 2,
        icon: <IoSettingsSharp />,
        link: "Settings",
        url: ROUTES.SETTINGS,
      },
      {
        id: 3,
        icon: <IoIosInformationCircle />,
        link: "Support",
        url: ROUTES.SUPPORT,
      },
    ],
  },
];

export const languages = [
  {
    id: "en",
    title: "EN",
    country: "English",
    flag: en,
  },
  {
    id: "sq",
    title: "SQ",
    country: "Albanian",
    flag: albania,
  },
];
