import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { sidebarLinks } from "./data";
import { useTranslation } from "react-i18next";
import { LOGO } from "assets";
import { Tooltip } from "components";

const AdminSidebar = () => {
  const pathname = useLocation().pathname;
  console.log("Current Path: ", pathname);
  const { t } = useTranslation();
  const sidebar_toggle = useSelector((state) => state.sidebarState.toggle);
  return (
    <div className={sidebar_toggle ? "admin_sidebar_none" : `admin_sidebar`}>
      <img src={LOGO} alt="" className="logo-img" />
      {sidebarLinks.map((link, i) => {
        return (
          <div className="admin_sidebar_content" key={i}>
            <p className="menu_title">{link.title}</p>
            {link.children.map((item) => (
              <div className="admin_sidebar_menu" key={item.id}>
                <Tooltip
                  title={item.link}
                  placement="right"
                  disableHoverListener={sidebar_toggle}
                >
                  <NavLink
                    to={item.url}
                    // activeClassName="adminActiveLink"
                    className={`sidebar_link${
                      pathname === item.url ? " adminActiveLink" : ""
                    }`}
                  >
                    <div className="flexed_div">
                      {item.img && (
                        <img
                          src={item.img}
                          alt=""
                          style={{
                            display: "block",
                            width: 30,
                            height: 30,
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {item.icon && item.icon}
                      <span>{t(item.link)}</span>
                    </div>
                  </NavLink>
                </Tooltip>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default AdminSidebar;
