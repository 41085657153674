import React, { forwardRef, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function SnackbarCustom(props) {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [state] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props.open}
      autoHideDuration={3000}
      onClose={props.handleClose}
      key={vertical + horizontal}
    >
      <Alert onClose={props.handleClose} severity={props.alertStatus}>
        {props.children}
      </Alert>
    </Snackbar>
  );
}
