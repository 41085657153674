import React from "react";

const Preloader = () => (
  <div class="three-body">
    <div class="three-body__dot"></div>
    <div class="three-body__dot"></div>
    <div class="three-body__dot"></div>
  </div>
);

export default Preloader;
