import axios from "axios";

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_FOO;
const locale = localStorage.getItem("i18nextLng");

export const axiosPublic = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "X-Authorization": API_KEY,
    locale: locale,
  },
});

const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "X-Authorization": API_KEY,
    locale: locale,
  },
});

axiosPrivate.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosPrivate };
