import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "styles/style.scss";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { Loader } from "components";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense fallback={Loader()}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Suspense>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
