import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { useAnimationOnScroll, useDocumentTitle, useScrollTop } from "hooks";
import * as PAGES from "pages";
import * as ROUTES from "constants/routes";
import DashboardRoute from "./routers/DashboardRoute";

function App() {
  useScrollTop();
  useAnimationOnScroll();
  const history = createBrowserHistory();
  useDocumentTitle("Trust Elev");
  return (
    <Router history={history}>
      <Routes>
        <Route path={ROUTES.SIGN_IN} element={<PAGES.SIGN_IN />} />

        <Route
          path={ROUTES.REPORTS}
          element={<DashboardRoute element={<PAGES.REPORTS />} />}
        />
        <Route
          path={ROUTES.SETTINGS}
          element={<DashboardRoute element={<PAGES.SETTINGS />} />}
        />
        <Route
          path={ROUTES.SUPPORT}
          element={<DashboardRoute element={<PAGES.SUPPORT />} />}
        />
        <Route path={ROUTES.LOADER} element={<PAGES.LOADER />} />
        <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} />} />
        {/* <Redirect to={ROUTES.LOADER} /> */}
      </Routes>
    </Router>
  );
}

export default App;
